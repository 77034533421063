.Header {
  width: 100%;
  padding: 11px 0 9px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  @include r(xs) {
    padding: 9px 0 5px;
    position: fixed;
    z-index: 10; }
  .Container {
    @include r(xs) {
      padding: 0; } }
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Left {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1; }
  &-Info {
    flex-shrink: 0; }
  &-Logo {
    width: 199px;
    @include r(xs) {
      width: 182px;
      margin: 0 auto;
      display: block; } }
  &-Descriptor {
    font-size: 18px;
    font-weight: 500;
    @include r(lg) {
      font-size: 15px; }
    @include r(sm) {
      margin-left: 15px; }
    @include r(xs) {
      width: 100%;
      margin: 0; }
    @include r(xs) {
      margin-top: 10px;
      font-size: 12px;
      text-align: center; } } }

.HeaderInfo {
  display: flex;
  flex-direction: column;
  &-Phone {}
  &-Text {
    @include r(xs) {
      display: none; } } }

.HeaderPhone {
  margin-bottom: 10px;
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  display: flex !important;
  align-items: center;
  @include r(xs) {
    width: 49px;
    height: 45px;
    padding: 16px 14px;
    background: $main-color;
    border-radius: 10px 0 0 10px;
    display: flex !important;
    align-items: center;
    justify-content: center; }
  &.Phone-Hidden {
    @include r(xs) {
      display: none !important; } }
  &:last-of-type {
    margin-bottom: 0; }
  &-Number {
    font-size: 16px;
    font-weight: 500;
    @include r(xs) {
      display: none; } }
  &-Img {
    display: none;
    @include r(xs) {
      width: 26px;
      display: block; } }
  &-Icon {
    margin-right: 11px; } }


.DefmenuBtn {
  background-color: $main-color;
  display: none;
  @include r(xs) {
    display: flex; } }

