.ColorSpectrum {
  background-color: #f3f9fc;
  &-Body {
    padding-top: 35px;
    padding-bottom: 55px;
    @include r(xs) {
      padding-top: 25px;
      padding-bottom: 8px; } }
  &-Title {
    margin-bottom: 10px;
    color: $main-color;
    text-align: center;
    &:before {
      width: 0; } }
  &-Subtitle {
    margin-bottom: 45px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    @include r(xs) {
      margin-bottom: 25px;
      font-size: 16px; } } }

.ColorSpectrumRow {
  margin-bottom: 15px;
  @include r(xs) {
    margin-bottom: 10px; }
  &:last-of-type {
    margin-bottom: 0; }
  &-Title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
    @include r(xs) {
      text-align: center; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r(sm) {
      justify-content: center; } } }

.ColorSpectrumCard {
  width: calc(20% - 20px * 4/5);
  height: 176px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include r(sm) {
    width: calc(33% - 20px * 2/3);
    margin-right: 20px;
    &:nth-child(3n) {
      margin-right: 0; } }
  @include r(xs) {
    width: calc(50% - 20px * 1/2);
    height: 142px;
    &:nth-child(3n) {
      margin-right: 20px; }
    &:nth-child(2n) {
      margin-right: 0; } }
  &-Img {
    max-width: 80%;
    max-height: 80%;
    display: block;
    @include r(xs) {
      max-width: 70%;
      max-height: 70%; } }
  &-Num {
    font-size: 14px;
    font-weight: 100;
    color: $main-color;
    position: absolute;
    left: 6px;
    bottom: 5px; } }
