.Input {
  display: flex;
  flex-direction: column;
  &-Control {
    height: 48px;
    padding-left: 20px;
    background-color: #f6f6f6;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    @include r(xs) {
      height: 48px; } } }
