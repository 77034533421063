.Tap {
  &-Body {
    padding-top: 37px;
    padding-bottom: 45px;
    @include r(xs) {
      padding-top: 15px;
      padding-bottom: 10px; } }
  &-Title {
    &:before {
      left: 6em; } }
  &-Products-Content {
    .ProductsCard {
      width: calc(25% - 8px * 3/4);
      @include r(sm) {
        width: calc(50% - 8px * 1/2); }
      @include r(xs) {
        width: 100%; }
      &-Title {
        margin-bottom: 5px; }
      &-ImgWrap {
        height: 226px;
        padding: 19px 14px;
        background-color: #e5f0f7;
        border-radius: 3px;
        align-items: flex-end; }
      &-Bottom {
          padding: 0;
          border: none; } } } }
