.Products {
  &-Body {
    padding-top: 40px;
    padding-bottom: 40px;
    @include r(xs) {
      padding-top: 25px;
      padding-bottom: 10px; } }
  &-Title {
    margin-bottom: 30px;
    @include r(xs) {
      margin-bottom: 15px; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r(xs) {
      justify-content: center; } }
  &-Wrap {
    margin-bottom: 65px;
    &:last-of-type {
      margin-bottom: 0; } } }

.ProductsCard {
  width: calc(33.33% - 36px*2/3);
  margin-bottom: 20px;
  padding: 5px 10px 18px;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  @include r(sm) {
    width: calc(33.33% - 15px*2/3); }
  @include r(xs) {
    width: 100%;
    max-width: 320px; }
  &-Title {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 500; }
  &-ImgWrap {
    width: 100%;
    height: 215px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-Img {
    max-width: 100%;
    max-height: 100%;
    display: block; }
  &-Characteristic {
    margin-bottom: 28px;
    font-size: 14px; }
  &-Bottom {
    padding-top: 7px;
    border-top: 2px solid #e4ebef; }
  &-Price {
    font-size: 14px;
    color: #000000;
    span {
      padding-top: 2px;
      font-size: 24px;
      font-weight: 700;
      color: $main-color;
      display: block; } } }
