.Phone {
  &-Only {
    display: none !important;
    @include r(xs) {
      display: inherit !important; } }
  &-Hidden {
    display: inherit !important;
    @include r(xs) {
      display: none !important; } } }

@import "../../common.blocks/Btn/Btn.sass";
@import "../../common.blocks/Btn/_disable/Btn_disable.sass";

@import "../../common.blocks/Select/Select.sass";
@import "../../common.blocks/Select/_theme_arrow/Select_theme_arrow.sass";

@import "../../common.blocks/Input/Input.sass";
@import "../../common.blocks/Input/_bgWhite/Input_bgWhite.sass";
@import "../../common.blocks/Input/_error/Input_error.sass";

@import "../../common.blocks/Form/Form.sass";
@import "../../common.blocks/Form/_theme_line/index.sass";
@import "../../common.blocks/FormThx/FormThx.sass";

@import "../../common.blocks/ProcessingPersonalDataText/ProcessingPersonalDataText.sass";

@import "../../common.blocks/Logo/Logo.sass";

@import "../../common.blocks/Checkbox/_theme_switch/Checkbox_theme_switch.sass";

@import "../../common.blocks/Accordion/Accordion.sass";


@import "../../project.blocks/Btn/index.sass";
@import "../../project.blocks/Input/index.sass";
@import "../../project.blocks/Title/index.sass";
@import "../../project.blocks/Form/index.sass";

