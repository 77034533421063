.Accordion {
  &-Item {
    margin-bottom: 13px;
    &_open {
      .Accordion-Title {
        &:before {
          transform: rotate(90deg); } }
      .Accordion-Content {
        display: block; } } }
  &-Title {
    padding-left: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      background-image: url(/img/AccordionArrow.png);
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 5px;
      left: 0; } }
  &-Content {
    padding-top: 20px;
    padding-left: 24px;
    padding-bottom: 20px;
    display: none; }
  &-Row {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0; } } }
