.SpecForm {
  background-image: url(/img/SpecForm-Bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &-Body {
    padding-top: 95px;
    padding-bottom: 85px;
    @include r(xs) {
      padding-top: 38px;
      padding-bottom: 38px; } } }

.SpecFormCard {
  width: 80%;
  padding: 35px 38px;
  background-color: #75d9fe;
  border-radius: 3px;
  position: relative;
  @include r(sm) {
    padding: 25px; }
  @include r(xs) {
    width: 100%;
    padding: 20px 11px 29px; }
  &-Title {
    margin-bottom: 7px;
    font-size: 36px;
    font-weight: 900;
    color: #ffffff;
    position: relative;
    z-index: 2;
    text-shadow: 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe, 0 0 5px #75d9fe;
    @include r(xs) {
      margin-bottom: 20px;
      font-size: 24px; } }
  &-Subtitle {
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    position: relative;
    z-index: 2;
    @include r(xs) {
      margin-bottom: 30px;
      font-size: 18px; }
    span {
      font-weight: 900;
      color: #3c3c3c; } }
  &-Form {
    max-width: 335px;
    position: relative;
    z-index: 2; }
  &-Simg {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translate(50%, -50%);
    @include r(sm) {
      width: 250px;
      top: 0;
      transform: translateX(50%); }
    @include r(xs) {
      width: 175px;
      top: 50px;
      right: -50px;
      transform: none; }
    &.Phone-Hidden {}
    &.Phone-Only {} } }
