.Advantage {
  &-Body {
    padding-top: 65px;
    padding-bottom: 40px;
    @include r(xs) {
      padding-top: 15px;
      padding-bottom: 25px; } }
  &-Top {
    margin-bottom: 60px;
    position: relative;
    @include r(xs) {
      margin-bottom: 30px; }
    &:before {
      content: '';
      width: 100%;
      height: 155px;
      background-image: url(/img/Advantage-TopLogo.png);
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1; }
    &-Text {
      max-width: 605px;
      margin: 0 auto 20px;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      display: block;
      @include r(xs) {
        margin-bottom: 13px;
        font-size: 14px; }
      &:last-of-type {
        margin-bottom: 0; } } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    @include r(xs) {
      justify-content: center; } } }

.AdvantageCard {
  width: 20%;
  padding: 20px 30px 0;
  border-right: 2px solid #f4f8fa;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @include r(sm) {
    padding: 0 15px; }
  @include r(xs) {
    width: 50%;
    margin-bottom: 35px;
    padding: 0 5px;
    border: none;
    align-items: center;
    justify-content: flex-start; }
  &:first-of-type {
    padding-left: 0; }
  &:last-of-type {
    margin: 0;
    padding-right: 0;
    border: none; }
  &-Icon {
    margin-bottom: 10px; }
  &-Text {
    font-size: 14px;
    font-weight: 500;
    @include r(xs) {
      text-align: center; } } }
