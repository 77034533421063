//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";

@include font-face("MuseoSansCyrl", "/font/MuseoSansCyrl100/MuseoSansCyrl100", 100, $file-formats: eot woff ttf svg);
@include font-face("MuseoSansCyrl", "/font/MuseoSansCyrl300/MuseoSansCyrl300", $file-formats: eot woff ttf svg);
@include font-face("MuseoSansCyrl", "/font/MuseoSansCyrl500/MuseoSansCyrl500", 500, $file-formats: eot woff ttf svg);
@include font-face("MuseoSansCyrl", "/font/MuseoSansCyrl700/MuseoSansCyrl700", 700, $file-formats: eot woff ttf svg);
@include font-face("MuseoSansCyrl", "/font/MuseoSansCyrl900/MuseoSansCyrl900", 700, $file-formats: eot woff ttf svg);

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  color: #3c3c3c; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

