.Intro {
  background-image: url(/img/Intro-Bg_ver1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 290px;
    background-image: url(/img/Intro-Bg_bottom.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 0;
    bottom: -1px; }
  &:after {
    content: '';
    width: 17px;
    height: 51px;
    background-image: url(/img/Intro-IconMouse.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: calc(50% - 8px);
    bottom: 25px; }
  &-Body {
    padding-top: 215px;
    padding-bottom: 190px;
    position: relative;
    z-index: 2;
    @include r(xs) {
      padding-top: 145px;
      padding-bottom: 170px; } }
  &-Content {}
  &-Title {
    padding-left: 19px;
    font-size: 36px;
    font-weight: 900;
    color: #ffffff;
    border-left: 3px solid #fff;
    @include r(xs) {
      padding-left: 11px;
      font-size: 22px; }
    br {
      @include r(sm) {
        display: none; } } }
  &-Form {
    margin-top: 60px;
    max-width: 622px;
    @include r(xs) {
      max-width: 350px;
      margin-top: 50px; } } }

.Intro_ver2 {
  background-image: url(/img/Intro-Bg_ver2.jpg);
  .Intro {
    &-Btn {
      width: 190px;
      margin-top: 45px; } } }
