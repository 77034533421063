.About {
  &-Body {
    padding-top: 10px;
    padding-bottom: 30px;
    @include r(xs) {
      padding-top: 30px;
      padding-bottom: 34px; } }
  &-Title {
    margin-bottom: 30px;
    @include r(xs) {
      margin-bottom: 12px; }
    span {
      font-weight: inherit;
      color: $main-color; }
    &:before {
      left: 11em; } }
  &-Subtitle {
    margin-bottom: 55px;
    padding-left: 19px;
    font-size: 18px;
    font-weight: 500;
    border-left: 4px solid $main-color;
    @include r(xs) {
      margin-bottom: 35px;
      padding-left: 9px;
      font-size: 14px; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; } }

.AboutAdvantage {
  width: calc(100% - 412px);
  display: flex;
  flex-wrap: wrap;
  @include r(sm) {
    width: 100%;
    justify-content: space-around; } }

.AboutAdvantageCard {
  width: 50%;
  margin-bottom: 75px;
  padding-right: 60px;
  @include r(xs) {
    width: 100%;
    max-width: 200px;
    margin-bottom: 25px;
    padding-right: 0;
    text-align: center; }
  &-Icon {
    margin-bottom: 25px;
    @include r(xs) {
      margin-bottom: 10px; } }
  &-Title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 900; }
  &-Text {
    font-size: 16px;
    font-weight: 100; } }

.AboutCertificate {
  width: 412px;
  padding: 30px 45px 35px;
  background-color: #e5f0f7;
  border-radius: 3px;
  @include r(xs) {
    width: calc(100% + 30px);
    padding: 30px 0 40px;
    position: relative; }
  &-Title {
    font-size: 24px;
    font-weight: 900;
    text-align: center; }
  &-Subtitle {
    margin-bottom: 28px;
    font-size: 18px;
    font-weight: 500;
    color: $main-color;
    text-align: center; }
  &-Link {
    width: 262px;
    margin: 0 auto;
    border: 10px solid #fafafa;
    display: block; }
  &-Img {
    width: 100%;
    display: block; } }
