.Checkbox_theme_switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  .Checkbox-Control {
    display: none;
    &:checked + .Checkbox-Slider {
      background-color: $main-color; }
    &:focus + .Checkbox-Slider {
      box-shadow: 0 0 1px $main-color; }
    &:checked + .Checkbox-Slider:before {
      transform: translateX(20px); } }
  .Checkbox-Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s; }
    &.Checkbox-Round {
      border-radius: 34px;
      &:before {
        border-radius: 50%; } } } }
