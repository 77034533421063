.Title {
  font-size: 36px;
  font-weight: 900;
  overflow: hidden;
  position: relative;
  @include r(xs) {
    font-size: 24px; }
  &:before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #e5f0f7;
    position: absolute;
    top: 50%;
    left: 10em; } }
