.LastForm {
  position: relative;
  z-index: 2;
  &-Body {
    padding-top: 65px;
    padding-bottom: 45px;
    @include r(xs) {
      padding-top: 50px;
      padding-bottom: 85px; } } }

.LastFormCard {
  max-width: 732px;
  margin-left: auto;
  margin-right: 80px;
  padding: 35px 54px 43px;
  background-color: #75d9fe;
  border-radius: 3px;
  position: relative;
  @include r(xs) {
    width: 100%;
    padding: 15px 25px 78px 16px;
    right: -20px; }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid #75d9fe;
    border-radius: 3px;
    position: absolute;
    top: -50px;
    left: -150px;
    z-index: -1;
    @include r(xs) {
      top: -13px;
      left: -30px; } }
  &-Title {
    max-width: 450px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 900;
    color: #ffffff;
    line-height: 1.1;
    @include r(xs) {
      margin-bottom: 20px;
      font-size: 24px; } }
  &-Subtitle {
    max-width: 450px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff; }
  &-Form {
    max-width: 335px;
    position: relative;
    z-index: 3; }
  &-Simg {
    position: absolute;
    &_catalog {
      left: -220px;
      bottom: -100px;
      z-index: -1;
      @include r(xs) {
        width: 179px;
        top: -120px;
        left: -45px; } }
    &_tap {
      right: -160px;
      bottom: 0;
      z-index: 2;
      @include r(xs) {
        width: 302px;
        right: -86px;
        bottom: -76px; } } }
  &-Logo {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; } }
