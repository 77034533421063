.Delivery {
  &-Body {
    padding-top: 25px;
    padding-bottom: 110px;
    @include r(xs) {
      padding-top: 10px;
      padding-bottom: 38px; } }
  &-Title {
    margin-bottom: 30px;
    @include r(xs) {
      margin-bottom: 20px; }
    &:before {
      left: 15em; }
    span {
      font-weight: inherit;
      color: $main-color; } }
  &-Subtitle {
    max-width: 630px;
    margin-bottom: 30px;
    padding-left: 19px;
    font-size: 18px;
    font-weight: 500;
    border-left: 2px solid $main-color;
    @include r(xs) {
      padding-left: 9px;
      font-size: 14px; } }
  &-Content {
    position: relative; }
  &-Map {
    max-width: calc(100% - 260px);
    position: absolute;
    top: -70px;
    right: 0;
    @include r(sm) {
      top: 0; }
    @include r(xs) {
      width: 100%;
      max-width: 320px;
      position: relative;
      top: -30px;
      left: 0; } } }

.DeliveryCard {
  max-width: 260px;
  padding: 25px 30px 30px 25px;
  background-color: $main-color;
  border-radius: 3px;
  position: relative;
  @include r(xs) {
    max-width: 320px; }
  &-Text {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    @include r(xs) {
      text-align: center; } }
  &-Line {
    width: 100%;
    height: 2px;
    margin-top: 30px;
    background-color: #ffffff;
    @include r(xs) {
      margin-top: 15px; } }
  &-Simg {
    position: absolute;
    top: calc(100% - 2px);
    left: 0; } }
